/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WatchlistsStyles from '../../../../styles/watchlist/watchlists.scss'
import WatchlistStyles from '../../../../styles/watchlist/watchlist.scss'
import * as WatchlistActions from '../../../../actions/watchlist.Actions'
import HoldingsPerformanceTable from './HoldingsPerformanceTable'
import HoldingsFundamentalsTable from './HoldingsFundamentalsTable'
import { ModalPopUp } from 'stifel-intyce-pattern-library'
import { SORTING_ORDER, WATCHLIST_MESSAGES, ISSUE_TYPES_MAP } from '../../../../utils/appConstants'
import { SetParentHeight } from '../../common.v2/CommonMethods'
import commonStyles from '../../../../styles/common.v2/common.scss'

class WatchlistHoldings extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      watchlistId: null,
      totalPages: null,
      pageNumber: parseInt(sessionStorage.getItem('watchlist-holdings-page-number') || 1),
      pageSize: parseInt(sessionStorage.getItem('watchlist-holdings-page-size') || 15),
      holdings: [],
      deletingSymbolInfo: {
        name: null,
        holdingId: null,
        deleting: false
      },
      isConfirmationModalPopupOpen: 0
    }

    this.props.setFetchingDataFlag({
      watchlistHoldings_Platform: {
        fetching: true,
        isHoldingFetched: false,
        isXidsFetched: false
      }
    })

    this.setPageSize = this.setPageSize.bind(this)
    this.setPageSizeEnter = this.setPageSizeEnter.bind(this)
    this.loadPrevious = this.loadPrevious.bind(this)
    this.loadNext = this.loadNext.bind(this)
    this.loadPreviousEnter = this.loadPreviousEnter.bind(this)
    this.loadNextEnter = this.loadNextEnter.bind(this)
    this.setHoldings = this.setHoldings.bind(this)
    this.deleteSymbol = this.deleteSymbol.bind(this)
    this.openModel = this.openModel.bind(this)
    this.closeModel = this.closeModel.bind(this)
    this.headerClickHandler = this.headerClickHandler.bind(this)
    this.headerClickHandlerEnter = this.headerClickHandlerEnter.bind(this)
    this.sortHoldings = this.sortHoldings.bind(this)
    this.openDeleteSymbolConfirmationModal = this.openDeleteSymbolConfirmationModal.bind(this)
    this.openDeleteSymbolConfirmationModalEnter = this.openDeleteSymbolConfirmationModalEnter.bind(this)
  }

  componentDidMount () {
  }
  componentDidUpdate (prevProps) {
    if (!this.props.isMobileView) {
      SetParentHeight()
    }
  }

  componentWillUnmount () {
    this.props.setFetchingDataFlag({
      watchlistHoldings_Platform: {
        data: {
          holdingDetails: []
        }
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.watchlistId ||
       (nextProps.watchlistId !== this.props.watchlistId) ||
       (this.props.deleteSymbolResponse && !nextProps.deleteSymbolResponse.isError &&
        this.props.deleteSymbolResponse.holdingId !== nextProps.deleteSymbolResponse.holdingId)) {
      this.props.setFetchingDataFlag({
        watchlistHoldings_Platform: {
          fetching: true,
          isErrorOccurred: false,
          errorInfo: {}
        }
      })

      this.setState({ watchlistId: this.props.watchlistId })
      this.props.requestWatchlistHoldings(nextProps.watchlistId, nextProps.holdingsBy === 'Fundamentals')
    }

    if (nextProps.totalSymbols !== undefined) {
      let pageSize = parseInt(sessionStorage.getItem('watchlist-holdings-page-size') || 15)
      this.setState({
        pageNumber: parseInt(sessionStorage.getItem('watchlist-holdings-page-number') || 1),
        pageSize: pageSize,
        totalPages: Math.ceil(nextProps.totalSymbols / pageSize)
      }, this.setHoldings(sessionStorage.getItem('watchlist-holdings-start-index') || 0, nextProps.holdings))

      if (nextProps.isHoldingFetched && nextProps.holdingsBy === 'Fundamentals') {
        if (nextProps.holdings && nextProps.holdings.length > 0) {
          this.props.requestWatchlistHoldingXidInstruments(nextProps.holdings.map((x) => x.symbol))
          this.props.setFetchingDataFlag({
            watchlistHoldings_Platform: {
              isHoldingFetched: false
            }
          })
        } else {
          this.props.setFetchingDataFlag({
            watchlistHoldings_Platform: {
              isHoldingFetched: false,
              fetching: false,
              isErrorOccurred: false,
              errorInfo: {}
            }
          })
        }
      }
      if (nextProps.isXidsFetched && nextProps.holdingsBy === 'Fundamentals') {
        let xinstrumentIds = nextProps.holdings.reduce((acc, curr) => {
          let tmpIssueType = ISSUE_TYPES_MAP[curr.issueType]
          if (!acc[tmpIssueType]) {
            acc[tmpIssueType] = []
          }
          acc[tmpIssueType].push(curr.xidInstrument)
          return acc
        }, {})
        this.props.requestWatchlistHoldingDividends(xinstrumentIds)
      }
    }

    // display delete symbol error message here
    if (this.props.deleteSymbolResponse && nextProps.deleteSymbolResponse.isError) {
      this.setState({
        deletingSymbolInfo: {
          deleting: false
        }
      })
      this.props.notificationHandler({show: true})
    }
  }

  loadPrevious () {
    if (this.state.pageNumber !== 1) {
      let startIndex = (parseInt(this.state.pageNumber) - 2) * this.state.pageSize

      this.setState({
        pageNumber: (this.state.pageNumber - 1)
      }, () => {
        this.setHoldings(startIndex)
      })
    }
  }

  loadPreviousEnter (e) {
    if (e.key === 'Enter') {
      this.loadPrevious()
    }
  }

  loadNext () {
    if (this.state.pageNumber !== this.state.totalPages) {
      let startIndex = 0

      if (this.state.pageNumber) {
        startIndex = parseInt(this.state.pageNumber) * this.state.pageSize
      }

      if (!this.state.pageNumber) {
        this.setState({
          pageNumber: 1
        }, () => {
          this.setHoldings(startIndex)
        })
      } else {
        this.setState({
          pageNumber: (parseInt(this.state.pageNumber) + 1)
        }, () => {
          this.setHoldings(startIndex)
        })
      }
    }
  }

  loadNextEnter (e) {
    if (e.key === 'Enter') {
      this.loadNext()
    }
  }

  getSortByInfo () {
    return {
      dataType: sessionStorage.getItem('watchlist-holdings-sorting-dataType') || 'Number',
      columnName: sessionStorage.getItem('watchlist-holdings-sorting-column') || 'changePct',
      order: sessionStorage.getItem('watchlist-holdings-sorting-order') || SORTING_ORDER.DECENDING
    }
  }

  setHoldings (startIndex, holdings) {
    startIndex = parseInt(startIndex)

    sessionStorage.setItem('watchlist-holdings-start-index', startIndex)
    sessionStorage.setItem('watchlist-holdings-page-size', sessionStorage.getItem('watchlist-holdings-page-size') || 15)
    sessionStorage.setItem('watchlist-holdings-page-number', this.state.pageNumber)

    let arrHoldings = holdings || this.props.holdings
    arrHoldings = arrHoldings.slice(0)
    let tempHoldings = this.sortHoldings(arrHoldings)

    this.setState({
      holdings: tempHoldings.slice(startIndex, startIndex + this.state.pageSize)
    }, () => {
    })
  }

  getLeftArrow () {
    if (this.state.pageNumber <= 1 ||
      this.props.totalSymbols === 0 ||
      this.state.pageNumber > this.state.totalPages) {
      return (
        <span className={`${WatchlistStyles['icon']} ${WatchlistStyles['icon-left-disabled']}`} />
      )
    }
    return (
      <span className={`${WatchlistStyles['icon']} ${WatchlistStyles['icon-left-active']}`} />
    )
  }

  getRightArrow () {
    if (this.state.pageNumber < 1 ||
      this.props.totalSymbols === 0 ||
      this.state.pageNumber >= this.state.totalPages) {
      return (
        <span className={`${WatchlistStyles['icon']} ${WatchlistStyles['icon-right-disabled']}`} />
      )
    }
    return (
      <span className={`${WatchlistStyles['icon']} ${WatchlistStyles['icon-right-active']}`} />
    )
  }

  getPaginationLabel () {
    let temp = parseInt(sessionStorage.getItem('watchlist-holdings-page-size') || this.state.pageSize) * this.state.pageNumber

    return 'Showing ' + (temp > this.props.totalSymbols ? this.props.totalSymbols : temp) + ' of ' + this.props.totalSymbols + ' investments'
  }

  setPageSize (event) {
    let pageSize = parseInt(event.target.getAttribute('data-page-size'))

    sessionStorage.setItem('watchlist-holdings-page-size', pageSize)

    this.setState({
      pageNumber: 1,
      pageSize: pageSize,
      totalPages: Math.ceil(this.props.totalSymbols / pageSize)
    }, () => {
      this.setHoldings(0)
    })
  }

  setPageSizeEnter (event) {
    if (event.key === 'Enter') {
      this.setPageSize(event)
    }
  }

  openDeleteSymbolConfirmationModal (e) {
    e.preventDefault()
    let el = e.currentTarget
    this.openModel()
    this.setState({
      deletingSymbolInfo: {
        holdingId: el.getAttribute('data-holdingid'),
        name: el.getAttribute('data-symbol')
      }
    })
  }

  openDeleteSymbolConfirmationModalEnter (e) {
    if (e.key === 'Enter') {
      this.openDeleteSymbolConfirmationModal(e)
    }
  }

  deleteSymbol (e) {
    e.preventDefault()
    if (this.state.deletingSymbolInfo.holdingId) {
      this.closeModel()
      this.setState({
        deletingSymbolInfo: {
          deleting: true,
          holdingId: this.state.deletingSymbolInfo.holdingId
        }
      })
      this.props.requestDeleteSymbol(this.props.watchlistId, this.state.deletingSymbolInfo.holdingId)
    }
  }

  openModel () {
    this.setState({
      isConfirmationModalPopupOpen: 1
    })
  }

  closeModel () {
    this.setState({
      isConfirmationModalPopupOpen: 0
    })
  }

  getSortOrder (target) {
    if (target) {
      let sortOrder = target.getAttribute('data-sort-order')
      let currentClickedColumn = target.getAttribute('data-col-name')
      let prevClickedColumn = sessionStorage.getItem('watchlist-holdings-sorting-column')

      if (!sortOrder && !prevClickedColumn && currentClickedColumn === 'changePct') {
        return SORTING_ORDER.ASCENDING
      }

      if (!sortOrder) {
        return SORTING_ORDER.DECENDING
      }

      if (sortOrder && prevClickedColumn === currentClickedColumn) {
        return sortOrder === SORTING_ORDER.DECENDING
          ? SORTING_ORDER.ASCENDING : SORTING_ORDER.DECENDING
      }
    }

    return SORTING_ORDER.DECENDING
  }

  headerClickHandler (e) {
    if (e) {
      e.preventDefault()

      let sortOrder = this.getSortOrder(e.currentTarget)
      let currentClickedColumn = e.currentTarget.getAttribute('data-col-name')

      let sortInfo = {
        order: sortOrder,
        columnName: currentClickedColumn,
        dataType: e.currentTarget.getAttribute('data-col-type')
      }

      e.currentTarget.setAttribute('data-sort-order', sortOrder)

      sessionStorage.setItem('watchlist-holdings-sorting-order', sortInfo.order)
      sessionStorage.setItem('watchlist-holdings-sorting-dataType', sortInfo.dataType)
      sessionStorage.setItem('watchlist-holdings-sorting-column', sortInfo.columnName)

      this.setHoldings(sessionStorage.getItem('watchlist-holdings-start-index') || 0)
    }
  }

  headerClickHandlerEnter (e) {
    if (e.key === 'Enter') {
      this.headerClickHandler(e)
    }
  }

  sortHoldings (holdings = []) {
    let sortInfo = this.getSortByInfo()
    let sortedHoldings = holdings.sort(function (obj1, obj2) {
      if (sortInfo.dataType === 'String') {
        let name1 = obj1[sortInfo.columnName].toLowerCase()
        let name2 = obj2[sortInfo.columnName].toLowerCase()

        if (name1 < name2) {
          return -1
        }

        if (name1 > name2) {
          return 1
        }

        return 0
      } else if (sortInfo.dataType === 'Number') {
        let value1 = obj1[sortInfo.columnName] || 0
        let value2 = obj2[sortInfo.columnName] || 0

        return sortInfo.order === SORTING_ORDER.ASCENDING
          ? value1 - value2 : value2 - value1
      }
    })

    if (sortInfo.dataType === 'String' &&
      sortInfo.order === SORTING_ORDER.DECENDING) {
      return sortedHoldings.reverse()
    } else {
      return sortedHoldings
    }
  }

  render () {
    if (this.props.fetching) {
      return (
        <div className={`${WatchlistStyles['watchlist-holdings-container']}`}>
          <div className={`${commonStyles['loader']} ${WatchlistsStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${WatchlistStyles['watchlist-holdings-container']}`}>
          <div data-error='true' className={`${WatchlistsStyles['error-container']}`}>
            <div>
              Oops! There was an error processing your request.<br />
              <div className={`${WatchlistsStyles['error-info']}`}>
                (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${WatchlistStyles['watchlist-holdings-container']}`}
        data-tab={this.props.holdingsBy}>
        {
          this.state.isConfirmationModalPopupOpen === 1 &&
          <ModalPopUp
            height='142px'
            onClick={() => { }}
            isOpen={this.state.isConfirmationModalPopupOpen}>
            <div tabIndex='0' className={`${WatchlistStyles['delete-symbol-container']}`}>
              <div className={`${WatchlistStyles['delete-symbol-header']}`}>
                <span>{WATCHLIST_MESSAGES.DELETE_SYMBOL_CONFIRMATION(this.state.deletingSymbolInfo.name)}</span>
              </div>
              <div className={`${WatchlistStyles['delete-symbol-footer']}`}>
                <form onSubmit={this.deleteSymbol}>
                  <button tabIndex='0' onClick={this.closeModel} onKeyPress={this.closeModel}>Cancel</button>
                  <button tabIndex='0' autoFocus type='submit'>Delete</button>
                </form>
              </div>
            </div>
          </ModalPopUp>
        }
        {
          this.props.holdingsBy === 'Performance' &&
            <HoldingsPerformanceTable
              onColHeaderClick={this.headerClickHandler}
              onColHeaderClickEnter={this.headerClickHandlerEnter}
              sortBy={this.getSortByInfo()}
              holdings={this.state.holdings}
              onSymbolClick={this.props.onSymbolClick}
              onSymbolClickEnter={this.props.onSymbolClickEnter}
              deletingSymbolInfo={this.state.deletingSymbolInfo}
              onDeleteSymbolClickEnter={this.openDeleteSymbolConfirmationModalEnter}
              onDeleteSymbolClick={this.openDeleteSymbolConfirmationModal} />
        }

        {
          this.props.holdingsBy === 'Fundamentals' &&
            <HoldingsFundamentalsTable
              onColHeaderClick={this.headerClickHandler}
              onColHeaderClickEnter={this.headerClickHandlerEnter}
              sortBy={this.getSortByInfo()}
              holdings={this.state.holdings}
              onSymbolClick={this.props.onSymbolClick}
              onSymbolClickEnter={this.props.onSymbolClickEnter}
              deletingSymbolInfo={this.state.deletingSymbolInfo}
              onDeleteSymbolClickEnter={this.openDeleteSymbolConfirmationModalEnter}
              onDeleteSymbolClick={this.openDeleteSymbolConfirmationModal} />
        }

        {
          this.state.holdings.length > 0 && <div className={`${WatchlistStyles['footer']}`}>
            <div className={`${WatchlistStyles['page-sizes']}`}>
              <ul>
                <li>Show</li>
                <li tabIndex='0' data-page-size='15' onClick={this.setPageSize} onKeyDown={this.setPageSizeEnter}
                  className={`${this.state.pageSize === 15 ? WatchlistStyles['selected'] : ''}`}>
                  15
                </li>
                <li tabIndex='0' data-page-size='30' onClick={this.setPageSize} onKeyDown={this.setPageSizeEnter}
                  className={`${this.state.pageSize === 30 ? WatchlistStyles['selected'] : ''}`}>
                  30
                </li>
                <li tabIndex='0' data-page-size='45' onClick={this.setPageSize} onKeyDown={this.setPageSizeEnter}
                  className={`${this.state.pageSize === 45 ? WatchlistStyles['selected'] : ''}`}>
                  45
                </li>
              </ul>
            </div>
            <div className={`${WatchlistStyles['pagination']}`}>
              <div className={`${WatchlistStyles['lbl-records-count']}`}>
                {this.getPaginationLabel()}
              </div>
              <div className={`${WatchlistStyles['action-buttons']}`}>
                <span tabIndex='0' onClick={this.loadPrevious} onKeyPress={this.loadPreviousEnter}>{this.getLeftArrow()}</span>
                <span>
                  Page <span className={`${WatchlistStyles['current-page-no']}`}>{this.state.pageNumber}</span> of {this.state.totalPages}
                </span>
                <span tabIndex='0' onClick={this.loadNext} onKeyPress={this.loadNextEnter}>{this.getRightArrow()}</span>
              </div>
            </div>
          </div>
        }

        {
          this.props.holdingsBy === 'Fundamentals' && this.state.holdings.length > 0 && <div className={`${WatchlistsStyles['dividend-disclosures']}`} aria-label='Dividend Disclosures'>
            Dividend frequencies may vary. Click on each symbol for more details.
          </div>
        }
      </div>
    )
  }
}

WatchlistHoldings.propTypes = {
  fetching: PropTypes.bool.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  errorInfo: PropTypes.object.isRequired,
  watchlistId: PropTypes.string.isRequired,
  holdingsBy: PropTypes.string.isRequired,
  requestWatchlistHoldings: PropTypes.func.isRequired,
  holdings: PropTypes.array.isRequired,
  onSymbolClick: PropTypes.func.isRequired,
  onSymbolClickEnter: PropTypes.func.isRequired,
  requestDeleteSymbol: PropTypes.func.isRequired,
  notificationHandler: PropTypes.func.isRequired,
  totalSymbols: PropTypes.number,
  deleteSymbolResponse: PropTypes.object,
  isMobileView: PropTypes.bool,
  requestWatchlistHoldingDividends: PropTypes.func.isRequired,
  requestWatchlistHoldingXidInstruments: PropTypes.func.isRequired,
  isHoldingFetched: PropTypes.bool.isRequired,
  isXidsFetched: PropTypes.bool.isRequired
}

function mapStateToProps (state, ownProps) {
  let obj = {
    fetching: state.watchlistReducer.watchlistHoldings_Platform.fetching,
    isErrorOccurred: !!state.watchlistReducer.watchlistHoldings_Platform.error,
    errorInfo: state.watchlistReducer.watchlistHoldings_Platform.error || {},
    holdings: [],
    isHoldingFetched: state.watchlistReducer.watchlistHoldings_Platform.isHoldingFetched,
    isXidsFetched: state.watchlistReducer.watchlistHoldings_Platform.isXidsFetched
  }

  if (!obj.isErrorOccurred) {
    Object.assign(obj, {
      holdings: state.watchlistReducer.watchlistHoldings_Platform.data.holdings,
      totalSymbols: state.watchlistReducer.watchlistHoldings_Platform.data.holdings.length,
      isHoldingFetched: state.watchlistReducer.watchlistHoldings_Platform.isHoldingFetched,
      isXidsFetched: state.watchlistReducer.watchlistHoldings_Platform.isXidsFetched
    })
  }

  if (state.watchlistReducer.deleteSymbolFromWatchlistResponse.error) {
    obj.deleteSymbolResponse = {
      isError: true,
      holdingId: null
    }
  } else {
    obj.deleteSymbolResponse = {
      isError: false,
      holdingId: (state.watchlistReducer.deleteSymbolFromWatchlistResponse.data.holdings.length > 0
        ? state.watchlistReducer.deleteSymbolFromWatchlistResponse.data.holdings[0].id : null)
    }
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestWatchlistHoldings: (id, updateFetchBool) => {
      return dispatch(WatchlistActions.requestWatchlistHoldings(id, false, updateFetchBool))
    },
    requestDeleteSymbol: (watchlistId, holdingId) => {
      return dispatch(WatchlistActions.requestDeleteSymbol(watchlistId, holdingId))
    },
    requestWatchlistHoldingDividends: (xids) => {
      return dispatch(WatchlistActions.requestWatchlistHoldingDividends(xids, 'CURRENT_EQUITY_DIVIDEND'))
    },
    requestWatchlistHoldingXidInstruments: (symbols) => {
      return dispatch(WatchlistActions.requestWatchlistHoldingXidInstruments(symbols))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistHoldings)
