/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WatchlistsStyles from '../../../../../styles/watchlist/watchlists.scss'
import WatchlistStyles from '../../../../../styles/watchlist/watchlist.scss'
import * as WatchlistActions from '../../../../../actions/watchlist.Actions'
import HoldingsPerformanceTable from './HoldingsPerformanceTable'
import HoldingsFundamentalsTable from './HoldingsFundamentalsTable'
import HoldingsBasicTable from './HoldingsBasicTable'
import { SORTING_ORDER, ISSUE_TYPES_MAP } from '../../../../../utils/appConstants'
import commonStyles from '../../../../../styles/common.v2/common.scss'

class WatchlistHoldings extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      watchlistInfo: null,
      holdings: []
    }
    this.props.setFetchingDataFlag({
      watchlistHoldings_Platform: {
        fetching: true,
        isHoldingFetched: false,
        isXidsFetched: false
      }
    })

    this.setHoldings = this.setHoldings.bind(this)
    this.headerClickHandler = this.headerClickHandler.bind(this)
    this.sortHoldings = this.sortHoldings.bind(this)
  }

  componentDidMount () {
  }

  componentWillUnmount () {
    this.props.setFetchingDataFlag({
      watchlistHoldings_Platform: {
        data: {
          holdingDetails: []
        }
      }
    })
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.watchlistInfo ||
       (nextProps.watchlistInfo.id !== this.props.watchlistInfo.id)) {
      this.props.setFetchingDataFlag({
        watchlistHoldings_Platform: {
          fetching: true,
          isErrorOccurred: false,
          errorInfo: {}
        }
      })

      this.setState({ watchlistInfo: this.props.watchlistInfo })
      this.props.requestWatchlistHoldings(nextProps.watchlistInfo.id, nextProps.holdingsBy === 'Fundamentals')
    }

    if (nextProps.totalSymbols !== undefined) {
      this.setHoldings(nextProps.holdings)

      if (nextProps.isHoldingFetched && nextProps.holdingsBy === 'Fundamentals') {
        if (nextProps.holdings && nextProps.holdings.length > 0) {
          this.props.requestWatchlistHoldingXidInstruments(nextProps.holdings.map((x) => x.symbol))
          this.props.setFetchingDataFlag({
            watchlistHoldings_Platform: {
              isHoldingFetched: false
            }
          })
        } else {
          this.props.setFetchingDataFlag({
            watchlistHoldings_Platform: {
              isHoldingFetched: false,
              fetching: false,
              isErrorOccurred: false,
              errorInfo: {}
            }
          })
        }
      }
      if (nextProps.isXidsFetched && nextProps.holdingsBy === 'Fundamentals') {
        let xinstrumentIds = nextProps.holdings.reduce((acc, curr) => {
          let tmpIssueType = ISSUE_TYPES_MAP[curr.issueType]
          if (!acc[tmpIssueType]) {
            acc[tmpIssueType] = []
          }
          acc[tmpIssueType].push(curr.xidInstrument)
          return acc
        }, {})
        this.props.requestWatchlistHoldingDividends(xinstrumentIds)
      }
    }
  }

  getSortByInfo () {
    return {
      dataType: sessionStorage.getItem('watchlist-holdings-sorting-dataType') || 'Number',
      columnName: sessionStorage.getItem('watchlist-holdings-sorting-column') || 'changePct',
      order: sessionStorage.getItem('watchlist-holdings-sorting-order') || SORTING_ORDER.DECENDING
    }
  }

  setHoldings (holdings) {
    let arrHoldings = holdings || this.props.holdings
    arrHoldings = arrHoldings.slice(0)
    let tempHoldings = this.sortHoldings(arrHoldings)

    this.setState({
      holdings: tempHoldings
    }, () => {
    })
  }

  getSortOrder (target) {
    if (target) {
      let sortOrder = target.getAttribute('data-sort-order')
      let currentClickedColumn = target.getAttribute('data-col-name')
      let prevClickedColumn = sessionStorage.getItem('watchlist-holdings-sorting-column')

      if (!sortOrder && !prevClickedColumn && currentClickedColumn === 'changePct') {
        return SORTING_ORDER.ASCENDING
      }

      if (!sortOrder) {
        return SORTING_ORDER.DECENDING
      }

      if (sortOrder && prevClickedColumn === currentClickedColumn) {
        return sortOrder === SORTING_ORDER.DECENDING
          ? SORTING_ORDER.ASCENDING : SORTING_ORDER.DECENDING
      }
    }

    return SORTING_ORDER.DECENDING
  }

  headerClickHandler (e) {
    if (e) {
      e.preventDefault()

      let sortOrder = this.getSortOrder(e.currentTarget)
      let currentClickedColumn = e.currentTarget.getAttribute('data-col-name')

      let sortInfo = {
        order: sortOrder,
        columnName: currentClickedColumn,
        dataType: e.currentTarget.getAttribute('data-col-type')
      }

      e.currentTarget.setAttribute('data-sort-order', sortOrder)

      sessionStorage.setItem('watchlist-holdings-sorting-order', sortInfo.order)
      sessionStorage.setItem('watchlist-holdings-sorting-dataType', sortInfo.dataType)
      sessionStorage.setItem('watchlist-holdings-sorting-column', sortInfo.columnName)

      this.setHoldings()
    }
  }

  sortHoldings (holdings = []) {
    let sortInfo = this.getSortByInfo()
    let sortedHoldings = holdings.sort(function (obj1, obj2) {
      if (sortInfo.dataType === 'String') {
        let name1 = obj1[sortInfo.columnName].toLowerCase()
        let name2 = obj2[sortInfo.columnName].toLowerCase()

        if (name1 < name2) {
          return -1
        }

        if (name1 > name2) {
          return 1
        }

        return 0
      } else if (sortInfo.dataType === 'Number') {
        let value1 = obj1[sortInfo.columnName] || 0
        let value2 = obj2[sortInfo.columnName] || 0

        return sortInfo.order === SORTING_ORDER.ASCENDING
          ? value1 - value2 : value2 - value1
      }
    })

    if (sortInfo.dataType === 'String' &&
      sortInfo.order === SORTING_ORDER.DECENDING) {
      return sortedHoldings.reverse()
    } else {
      return sortedHoldings
    }
  }

  render () {
    if (this.props.fetching) {
      return (
        <div className={`${WatchlistStyles['watchlist-holdings-container']}`}>
          <div className={`${commonStyles['loader']} ${WatchlistsStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']}`} />
          </div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${WatchlistStyles['watchlist-holdings-container']}`}>
          <div data-error='true' className={`${WatchlistsStyles['error-container']}`}>
            <div>
              Oops! There was an error processing your request.<br />
              <div className={`${WatchlistsStyles['error-info']}`}>
                (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={`${WatchlistStyles['watchlist-holdings-container']}`}
        data-tab={this.props.holdingsBy}>
        {
          this.props.holdingsBy === 'Basic' &&
            <HoldingsBasicTable
              watchlistInfo={this.props.watchlistInfo}
              onColHeaderClick={this.headerClickHandler}
              sortBy={this.getSortByInfo()}
              holdings={this.state.holdings}
              onSymbolClick={this.props.onSymbolClick} />
        }

        {
          this.props.holdingsBy === 'Performance' &&
            <HoldingsPerformanceTable
              watchlistInfo={this.props.watchlistInfo}
              onColHeaderClick={this.headerClickHandler}
              sortBy={this.getSortByInfo()}
              holdings={this.state.holdings}
              onSymbolClick={this.props.onSymbolClick} />
        }

        {
          this.props.holdingsBy === 'Fundamentals' &&
            <HoldingsFundamentalsTable
              watchlistInfo={this.props.watchlistInfo}
              onColHeaderClick={this.headerClickHandler}
              sortBy={this.getSortByInfo()}
              holdings={this.state.holdings}
              onSymbolClick={this.props.onSymbolClick} />
        }

        {
          this.props.holdingsBy === 'Fundamentals' && this.state.holdings.length > 0 && <div className={`${WatchlistsStyles['dividend-disclosures']}`} aria-label='Dividend Disclosures'>
            Dividend frequencies may vary. Click on each symbol for more details.
          </div>
        }
      </div>
    )
  }
}

WatchlistHoldings.propTypes = {
  fetching: PropTypes.bool.isRequired,
  setFetchingDataFlag: PropTypes.func.isRequired,
  isErrorOccurred: PropTypes.bool.isRequired,
  errorInfo: PropTypes.object.isRequired,
  watchlistInfo: PropTypes.object.isRequired,
  holdingsBy: PropTypes.string.isRequired,
  requestWatchlistHoldings: PropTypes.func.isRequired,
  holdings: PropTypes.array.isRequired,
  onSymbolClick: PropTypes.func.isRequired,
  totalSymbols: PropTypes.number,
  requestWatchlistHoldingDividends: PropTypes.func.isRequired,
  requestWatchlistHoldingXidInstruments: PropTypes.func.isRequired,
  isHoldingFetched: PropTypes.bool.isRequired,
  isXidsFetched: PropTypes.bool.isRequired
}

function mapStateToProps (state, ownProps) {
  let obj = {
    fetching: state.watchlistReducer.watchlistHoldings_Platform.fetching,
    isErrorOccurred: !!state.watchlistReducer.watchlistHoldings_Platform.error,
    errorInfo: state.watchlistReducer.watchlistHoldings_Platform.error || {},
    holdings: [],
    isHoldingFetched: state.watchlistReducer.watchlistHoldings_Platform.isHoldingFetched,
    isXidsFetched: state.watchlistReducer.watchlistHoldings_Platform.isXidsFetched
  }

  if (!obj.isErrorOccurred) {
    Object.assign(obj, {
      holdings: state.watchlistReducer.watchlistHoldings_Platform.data.holdings,
      totalSymbols: state.watchlistReducer.watchlistHoldings_Platform.data.holdings.length
    })
  }

  return obj
}

function mapDispatchToProps (dispatch) {
  return {
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(WatchlistActions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestWatchlistHoldings: (id, updateFetchBool) => {
      return dispatch(WatchlistActions.requestWatchlistHoldings(id, false, updateFetchBool))
    },
    requestWatchlistHoldingDividends: (xids) => {
      return dispatch(WatchlistActions.requestWatchlistHoldingDividends(xids, 'CURRENT_EQUITY_DIVIDEND'))
    },
    requestWatchlistHoldingXidInstruments: (symbols) => {
      return dispatch(WatchlistActions.requestWatchlistHoldingXidInstruments(symbols))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistHoldings)
