import Types from '../actions/types'
import { deepMerge } from '../../src/utils/deepMerge'
import { createReducer } from 'reduxsauce'
import INITIAL_STATE from './initial-state/snapshot.V2'

const receiveSnapshotV2Data = (state = INITIAL_STATE, action) => {
  return Object.assign({}, state, {
    fetching: false,
    quoteData: Object.assign({}, state.quoteData, action.data.quoteData.data),
    quoteData_Platform: deepMerge(state.quoteData_Platform, action.data.quoteData_Platform),
    symbolXREFData_Platform: Object.assign({}, state.symbolXREFData_Platform, action.data.symbolXREFData_Platform ? action.data.symbolXREFData_Platform.data : null)
  })
}

const receiveSnapshotV2FundIndicatorData = (state = INITIAL_STATE, action) => {
  let obj = {
    fetching: false,
    data: {
      fundIndicator: (action.data &&
        action.data.fundIndicator_Platform) || null
    }
  }
  return Object.assign({}, state, {
    fundIndicator_Platform: obj
  })
}

const receiveSnapshotV2OptionChainData = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false,
      optionChain: action.data.optionChain_Platform
    }
  }

  return Object.assign({}, state, { fetchOptionChainData: false }, {
    optionChain_Platform: deepMerge(state.optionChain_Platform, obj)
  })
}

const receiveSnapshotV2OptionChainExpirationDatesData = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false,
      topExpDate: action.data.optionChainsExpirationDates_Platform &&
        action.data.optionChainsExpirationDates_Platform.data &&
        action.data.optionChainsExpirationDates_Platform.data.dates &&
        action.data.optionChainsExpirationDates_Platform.data.dates.length > 0 &&
        action.data.optionChainsExpirationDates_Platform.data.dates[0].expirationDate,
      dates: action.data.optionChainsExpirationDates_Platform &&
        action.data.optionChainsExpirationDates_Platform.data &&
        action.data.optionChainsExpirationDates_Platform.data.dates
    }

  }
  return Object.assign({}, state, { fetchOptionChainData: true }, {
    optionChainExpirationDates_Platform: deepMerge(state.optionChainExpirationDates_Platform, obj)
  })
}

const receiveSnapshotV2MFOverviewData = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false,
      fundObjective: action.payload.mfOverviewFundObjective && (
        action.payload.mfOverviewFundObjective.error
          ? null
          : action.payload.mfOverviewFundObjective.data.investmentBasics[0].principalInvestmentObjective),
      mfOverview10KGrowthChart: {
        fetching: false,
        chartUrl: action.payload.mfOverview10KGrowthChart.data.chartUrl,
        chartUrlIpad: action.payload.mfOverview10KGrowthChart.data.chartUrlIpad,
        chartUrlMobile: action.payload.mfOverview10KGrowthChart.data.chartUrlMobile
      }
    }
  }

  return Object.assign({}, state, {
    mfOverviewData: deepMerge(state.mfOverviewData, obj)
  })
}

const receiveSnapshotV2MFGrowth10KChartData = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      mfOverview10KGrowthChart: {
        fetching: false,
        chartUrl: action.payload.mfOverview10KGrowthChart.data.chartUrl,
        chartUrlIpad: action.payload.mfOverview10KGrowthChart.data.chartUrlIpad,
        chartUrlMobile: action.payload.mfOverview10KGrowthChart.data.chartUrlMobile
      }
    }
  }

  return Object.assign({}, state, {
    mfOverviewData: deepMerge(state.mfOverviewData, obj)
  })
}

let equityDividendHistory = {
  data: {
    dividends: [
      {
        dividend: {
          DivCurrency: '',
          ExDate: '',
          GrossAmount: '',
          Indicator: ''
        }
      }
    ]
  }
}

let equitySplitHistory = {
  data: {
    dividends: [
      {
        dividend: {
          ExDate: '',
          Indicator: '',
          Terms: ''
        }
      }
    ]
  }
}

const receiveSnapshotV2ETFOverviewData = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false,
      fundObjective: action.payload.etfOverviewFundObjective && (
        action.payload.etfOverviewFundObjective.error
          ? null
          : action.payload.etfOverviewFundObjective.data.investmentBasics[0].principalInvestmentObjective)
    }
  }
  let dividend = (action.payload.equityDividend_History && action.payload.equityDividend_History.data && action.payload.equityDividend_History.data.dividends.length &&
    action.payload.equityDividend_History.data.dividends[0].dividend) ? action.payload.equityDividend_History : equityDividendHistory
  let split = (action.payload.equitySplit_History && action.payload.equitySplit_History.data && action.payload.equitySplit_History.data.dividends.length &&
    action.payload.equitySplit_History.data.dividends[0].dividend) ? action.payload.equitySplit_History : equitySplitHistory
  return Object.assign({}, state, {
    etfOverviewData: deepMerge(state.etfOverviewData, obj),
    equityDividend_History: Object.assign({}, state.equityDividend_History, dividend),
    equitySplit_History: Object.assign({}, state.equitySplit_History, split)
  })
}

const receiveSnapshotV2XidFromSymbol = (state = INITIAL_STATE, action) => {
  let objXidFromSymbolesponse = {
    fetching: false
  }

  if (action.payload.xidFromSymbol_Platform && action.payload.xidFromSymbol_Platform.error) {
    Object.assign(objXidFromSymbolesponse, action.payload.xidFromSymbol_Platform)
  } else {
    objXidFromSymbolesponse['data'] = action.payload.xidFromSymbol_Platform.data
  }

  return Object.assign({}, state, {
    xidFromSymbol: objXidFromSymbolesponse
  })
}

const receiveSnapshotV2NewsData = (state = INITIAL_STATE, action) => {
  let objNewsResponse = {
    fetching: false
  }

  let dataStoreKeyName = Object.keys(action.payload)[0]

  if (action.payload[dataStoreKeyName] && action.payload[dataStoreKeyName].error) {
    Object.assign(objNewsResponse, action.payload[dataStoreKeyName])
  } else {
    objNewsResponse['data'] = action.payload[dataStoreKeyName].data
  }

  return Object.assign({}, state, {
    [dataStoreKeyName]: objNewsResponse
  })
}

const receiveSnapshotV2GeneralInfo = (state = INITIAL_STATE, action) => {
  let obj = {
    fetching: false,
    data: (action.payload.generalInfo === null ||
        action.payload.generalInfo.error)
      ? null
      : action.payload.generalInfo.data
  }
  let dividend = (action.payload.equityDividend_History && action.payload.equityDividend_History.data && action.payload.equityDividend_History.data.dividends.length &&
    action.payload.equityDividend_History.data.dividends[0].dividend) ? action.payload.equityDividend_History : equityDividendHistory
  let split = (action.payload.equitySplit_History && action.payload.equitySplit_History.data && action.payload.equitySplit_History.data.dividends.length &&
    action.payload.equitySplit_History.data.dividends[0].dividend) ? action.payload.equitySplit_History : equitySplitHistory
  return Object.assign({}, state, {
    generalInfo: Object.assign({}, state.generalInfo, obj),
    equityDividend_History: Object.assign({}, state.equityDividend_History, dividend),
    equitySplit_History: Object.assign({}, state.equitySplit_History, split)
  })
}

const receiveSnapshotV2PerformanceSummaryData = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false,
      topHoldings_Platform: (action.payload.topHoldings_Platform === null ||
        action.payload.topHoldings_Platform.error)
        ? INITIAL_STATE.performanceSummary.data.topHoldings_Platform
        : action.payload.topHoldings_Platform,
      prospectusFees_Platform: (action.payload.prospectusFees_Platform === null ||
        action.payload.prospectusFees_Platform.error)
        ? INITIAL_STATE.performanceSummary.data.prospectusFees_Platform
        : action.payload.prospectusFees_Platform,
      shareClassInformation_Platform: (action.payload.shareClassInformation_Platform === null ||
        action.payload.shareClassInformation_Platform.error)
        ? INITIAL_STATE.performanceSummary.data.shareClassInformation_Platform
        : action.payload.shareClassInformation_Platform,
      trailingMonthEnd_Platform: (action.payload.trailingMonthEnd_Platform === null ||
        action.payload.trailingMonthEnd_Platform.error)
        ? INITIAL_STATE.performanceSummary.data.trailingMonthEnd_Platform
        : action.payload.trailingMonthEnd_Platform,
      chartUrl: action.payload.revenueChart
    }
  }
  return Object.assign({}, state, {
    performanceSummary: Object.assign({}, state.performanceSummary, obj)
  })
}

const receiveRevenueChart = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false
    }
  }

  if (action.payload.revenueChart && action.payload.revenueChart.data) {
    obj.data['chartUrl'] = action.payload.revenueChart.data.chartUrl
    obj.data['chartWidth'] = action.payload.revenueChart.data.chartWidth
    obj.data['chartHeight'] = action.payload.revenueChart.data.chartHeight
  }

  return Object.assign({}, state, {
    revenueChart: Object.assign({}, state.revenueChart, obj)
  })
}

let equityDividendPlatform = {
  fetching: true,
  data: {
    dividends: [
      {
        dividend: {
          AnnDate: '',
          DivAmountGross: null,
          DivCurrency: '',
          DivFrequency: 0,
          DivType: '--',
          DivYieldAsOfDate: '',
          DivYieldGross: '--',
          ExDate: '',
          PayDate: '',
          RecordDate: ''
        }
      }
    ]
  }
}

const recieveDividendData = (state = INITIAL_STATE, action) => {
  let equityDividendPlatforms = (action.data.equityDividend_Platform && action.data.equityDividend_Platform.data && action.data.equityDividend_Platform.data.dividends && action.data.equityDividend_Platform.data.dividends[0].dividend) ? action.data.equityDividend_Platform : equityDividendPlatform
  return Object.assign({}, state, {
    equityDividend_Platform: Object.assign({}, state.equityDividend_Platform, equityDividendPlatforms)
  })
}

const recieveDividendChart = (state = INITIAL_STATE, action) => {
  let obj = {
    data: {
      fetching: false
    }
  }

  if (action.payload.dividendChart && action.payload.dividendChart.data) {
    obj.data['chartUrl'] = action.payload.dividendChart.data.chartUrl
    let dataCoordinatesTemp = JSON.parse(action.payload.dividendChart.data.dataExport)
    obj.data['dataCoordinates'] = dataCoordinatesTemp ? dataCoordinatesTemp.dataCoordinates : []
    obj.data['chartWidth'] = action.payload.dividendChart.data.chartWidth
    obj.data['chartHeight'] = action.payload.dividendChart.data.chartHeight
    obj.data['chartFrequencyType'] = action.payload.dividendChart.data.chartFrequencyType
  }

  return Object.assign({}, state, {
    dividendChart: Object.assign({}, deepMerge(state.dividendChart, obj))
  })
}

const receiveSnapshotV2NewsArticleData = (state = INITIAL_STATE, action) => {
  let objNewsArticleResponse = {
    fetching: false
  }

  if (!action.payload.newsArticle) {
    Object.assign(objNewsArticleResponse, {
      error: {
        code: 500,
        message: 'Unknown Error'
      }
    })
  } else if (action.payload.newsArticle && action.payload.newsArticle.error) {
    Object.assign(objNewsArticleResponse, action.payload.newsArticle)
  } else {
    objNewsArticleResponse['data'] = action.payload.newsArticle.data
  }

  return Object.assign({}, state, {
    newsArticle: objNewsArticleResponse
  })
}

const receiveSnapshotV2NewsImageData = (state = INITIAL_STATE, action) => {
  return Object.assign({}, state, {
    newsImage: action.payload.newsImage.data || []
  })
}

const setFetchingDataFlag = (state, action) => {
  return Object.assign({}, deepMerge(state, action.payload))
}

const ACTION_HANDLERS = {
  [Types.SET_FETCHING_DATA_FLAG]: setFetchingDataFlag,
  [Types.GET_SNAPSHOT_V2_DATA_API_SUCCESS]: receiveSnapshotV2Data,
  [Types.GET_SNAPSHOT_V2_FUND_INDICATOR_DATA_API_SUCCESS]: receiveSnapshotV2FundIndicatorData,
  [Types.GET_SNAPSHOT_V2_10K_GROWTH_CHART_DATA_API_SUCCESS]: receiveSnapshotV2MFGrowth10KChartData,
  [Types.GET_SNAPSHOT_V2_OPTION_CHAIN_DATA_API_SUCCESS]: receiveSnapshotV2OptionChainData,
  [Types.GET_SNAPSHOT_V2_OPTION_CHAIN_EXPIRATION_DATES_DATA_API_SUCCESS]: receiveSnapshotV2OptionChainExpirationDatesData,
  [Types.GET_SNAPSHOT_V2_MF_OVERVIEW_DATA_API_SUCCESS]: receiveSnapshotV2MFOverviewData,
  [Types.GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API_SUCCESS]: receiveSnapshotV2ETFOverviewData,
  [Types.GET_SNAPSHOT_V2_ETF_OVERVIEW_DATA_API_SUCCESS]: receiveSnapshotV2ETFOverviewData,
  [Types.GET_SNAPSHOT_V2_XID_FROM_SYMBOL_API_SUCCESS]: receiveSnapshotV2XidFromSymbol,
  [Types.GET_SNAPSHOT_V2_NEWS_API_SUCCESS]: receiveSnapshotV2NewsData,
  [Types.GET_SNAPSHOT_V2_GENERALINFO_API_SUCCESS]: receiveSnapshotV2GeneralInfo,
  [Types.GET_SNAPSHOT_V2_NEWS_ARTICLE_API_SUCCESS]: receiveSnapshotV2NewsArticleData,
  [Types.GET_SNAPSHOT_V2_PERFORMANCE_SUMMARY_API_SUCCESS]: receiveSnapshotV2PerformanceSummaryData,
  [Types.GET_SNAPSHOT_V2_NEWS_IMAGE_API_SUCCESS]: receiveSnapshotV2NewsImageData,
  [Types.REQUEST_REVENUE_CHART_API_SUCCESS]: receiveRevenueChart,
  [Types.REQUEST_DIVIDEND_CHART_API_SUCCESS]: recieveDividendChart,
  [Types.GET_MARKETS_EQUITY_DIVIDENT_DATA_API_SUCCESS]: recieveDividendData
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
