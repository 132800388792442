import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import * as SnapshotV2Actions from '../../../../actions/snapshot.V2.Actions'
import overviewStockStyles from '../../../../styles/snapshot.V2/overviewStock.scss'
import { RenderTextWithHeading, Format } from 'stifel-intyce-pattern-library'
import Chart from './Chart'
import { DATE_TIME_CONSTANTS, ISSUE_TYPES } from '../../../../utils/appConstants'
import commonStyles from '../../../../styles/common.v2/common.scss'

class OverviewStock extends React.Component {
  getLinkTo () {
    return false
  }
  componentDidMount () {
    this.props.requestSnapshotV2GeneralInfo(this.props.symbol, this.props.symbolXREFData ? this.props.symbolXREFData.xids : '')
  }
  render () {
    if (this.props.fetching) {
      return (
        <div className={`${overviewStockStyles['stock-overview-container']}`}>
          <div className={`${commonStyles['loader']} ${commonStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']} ${overviewStockStyles['stock-loader']}`} />
          </div>
        </div>
      )
    }
    return (
      <div className={`${overviewStockStyles['stock-overview-container']}`}>
        {this.props.generalInfo.data && this.props.generalInfo.data.companySummary &&
          <div className={`${overviewStockStyles['companySummary']}`}>
            <div className={`${overviewStockStyles['companySummaryText']}`}>
              <RenderTextWithHeading
                heading='Company Summary'
                content={this.props.generalInfo.data && this.props.generalInfo.data.companySummary} />
            </div>
          </div>
        }
        <div className={`${overviewStockStyles['chart-view']}`}>
          <Chart showHighLow isMobileView={this.props.isMobileView} handleResize={this.props.handleResize} symbol={this.props.symbol}
            dividendHistory={this.props.dividendHistory} splitHistory={this.props.splitHistory} wsodIssue={this.props.wsodIssue} issueType={ISSUE_TYPES.Stock} />
        </div>
        <div className={`${overviewStockStyles['bottom-container']}`}>
          <span data-el-identifier='overview-stock-date_footer' className={`${overviewStockStyles['date']}`}>
            {Format.getFormattedDateTime(this.props.dateAsOf, {preFix: DATE_TIME_CONSTANTS.AS_OF_PREFIX, tzConversion: true})}
          </span>
        </div>
      </div>
    )
  }
}

OverviewStock.propTypes = {
  fetching: PropTypes.bool.isRequired,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symbol: PropTypes.string.isRequired,
  handleResize: PropTypes.func,
  isMobileView: PropTypes.bool,
  generalInfo: PropTypes.object,
  requestSnapshotV2GeneralInfo: PropTypes.func,
  symbolXREFData: PropTypes.object,
  dividendHistory: PropTypes.object,
  splitHistory: PropTypes.object,
  dateAsOf: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    fetching: state.snapshotV2Reducer.generalInfo.fetching,
    generalInfo: state.snapshotV2Reducer.generalInfo,
    symbolXREFData: state.snapshotV2Reducer.symbolXREFData_Platform,
    dividendHistory: state.snapshotV2Reducer.equityDividend_History ? state.snapshotV2Reducer.equityDividend_History.data.dividends[0].dividend : null,
    splitHistory: state.snapshotV2Reducer.equitySplit_History ? state.snapshotV2Reducer.equitySplit_History.data.dividends[0].dividend : null
  }
}

function mapDispatchToProps (dispatch) {
  return {
    requestSnapshotV2GeneralInfo: (symbol, instrumentXIDs) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2GeneralInfo(symbol, instrumentXIDs))
    },
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewStock)
