/* eslint-disable */
import { connect } from 'react-redux'
import React from 'react'
import { PerformanceSummaryStock, PerformanceSummaryMF, PerformanceSummaryETF, PerformanceSummaryIndex } from 'stifel-intyce-pattern-library'
import * as SnapshotV2Actions from '../../../actions/snapshot.V2.Actions'
import { ISSUE_TYPES, SHOW_DIVIDEND_DATA } from '../../../utils/appConstants'
import $ from 'jquery'
import commonStyles from '../../../styles/common.v2/common.scss'
import { globalConfig } from '../../../utils/globalConfig'
class PerformanceSummaryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.setFetchingDataFlag({
      performanceSummary: {
        data: {
          fetching: true
        }
      }
    })
    this.props.setFetchingDataFlag({
      performanceSummaryStock: {
        data: {
          fetching: true
        }
      }
    })
    this.props.setFetchingDataFlag({
      revenueChart: {
        data: {
          fetching: true
        }
      }
    })
    this.props.setFetchingDataFlag({
      dividendChart: {
        data: {
          fetching: true,
          isMobileView: this.props.isMobileView
        }
      }
    })

    this.state = {
      loadRevenueChartTime: null,
      loadDividendChartTime: null
    }
  }

  componentWillUnmount () {
    if (this.state.loadRevenueChartTime) {
      clearTimeout(this.state.loadRevenueChartTime)
    }

    if (this.state.loadDividendChartTime) {
      clearTimeout(this.state.loadDividendChartTime)
    }
  }

  componentDidMount() {
    if (this.props.securityType == ISSUE_TYPES.Etf) {
      this.props.requestSnapshotV2FundIndicatorData(this.props.wsodIssue)
    }
    if (this.props.securityType == ISSUE_TYPES.MutualFund || this.props.securityType == ISSUE_TYPES.Etf) {
      this.props.requestSnapshotV2PerformanceSummaryData(this.props.wsodIssue)

      let dividendInterval = window.setInterval(() => {
        const width = $('#dividend-chart-container').width()

        if (width) {
          if (this.props.symbolXREFData && this.props.symbolXREFData.xids) {
            this.props.requestDividendChart(this.props.symbolXREFData.xids, width, this.props.securityType)

            this.setState({
              loadDividendChartTime: dividendInterval
            })

            clearInterval(dividendInterval);
          }
        }
			}, 1000);
    } else {
      if (this.props.securityType == ISSUE_TYPES.Stock) {
        
        let revenueInterval = window.setTimeout(()=>{
          let width = $('#revenue-chart-container').width()
          if(!width || width ===0) {
            width = 400
          }

          this.props.requestRevenueChart(this.props.wsodIssue, width)
        },1000);

        this.setState({
          loadRevenueChartTime: revenueInterval
        })
			let dividendInterval = window.setTimeout(() => {
        const width = $('#dividend-chart-container').width() || 250
        if(this.props.symbolXREFData && this.props.symbolXREFData.xids){
          this.props.requestDividendChart(this.props.symbolXREFData.xids, width, this.props.securityType)
        }
			}, 1000);
		

        this.setState({
          loadDividendChartTime: dividendInterval
        })
    }
      this.props.setFetchingDataFlag({
        performanceSummary: {
          data: {
            fetching: false
          }
        },
        performanceSummaryStock: {
          data: {
            fetching: false
          }
        }
      })
    }
    if(this.props.symbolXREFData && this.props.symbolXREFData.xids){
      this.props.requestSnapshotV2DividendData(this.props.symbolXREFData.xids, this.props.securityType)
    }
    this.props.handleResize()
  }

  render() {
    if (((this.props.fetching && this.props.securityType == ISSUE_TYPES.MutualFund) || this.props.fetching ||  (this.props.securityType == ISSUE_TYPES.Etf && this.props.fetchingQuoteETF))) {
      return (
        <div style={{'minHeight': '320px'}}>
          <div className={`${commonStyles['loader']} ${commonStyles['loader-no-border']}`}>
            <div className={`${commonStyles['rollingloader']} ${commonStyles['rollingloader-pos']}`}></div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          {this.props.securityType === ISSUE_TYPES.Stock &&
            <PerformanceSummaryStock
              lastPrice={this.props.lastPrice}
              todaysPriceChange={this.props.todaysPriceChange}
              todaysPricePercentChange={this.props.todaysPricePercentChange}
              open={this.props.open}
              openGap={this.props.openGap}
              previousClose={this.props.previousClose}
              tradingVolume={this.props.tradingVolume}
              avgVolume10D={this.props.avgVolume10D}
              sharesOutstanding={this.props.sharesOutstanding}
              marketCap={this.props.marketCap}
              tradingActivity={this.props.tradingActivity}
              earningsPrv12M={this.props.earningsPrv12M}
              earningsEstimate12M={this.props.earningsEstimate12M}
              ratioPE={this.props.ratioPE}
              revenue={this.props.revenue}
              change3M={this.props.change3M}
              changePct3M={this.props.changePct3M}
              change6M={this.props.change6M}
              changePct6M={this.props.changePct6M}
              change1Y={this.props.change1Y}
              changePct1Y={this.props.changePct1Y}
              change2Y={this.props.change2Y}
              changePct2Y={this.props.changePct2Y}
              isMarketOpen={this.props.isMarketOpen}
              revenueChart={this.props.revenueChart}
              dividendChart={this.props.dividendChart}
              dividendData={this.props.dividendData}
            />
          }

          {
            this.props.securityType === ISSUE_TYPES.Indices &&
            <PerformanceSummaryIndex
              lastPrice={this.props.lastPrice}
              todaysPriceChange={this.props.todaysPriceChange}
              todaysPricePercentChange={this.props.todaysPricePercentChange}
              open={this.props.open}
              openGap={this.props.openGap}
              previousClose={this.props.previousClose}
              tradingVolume={this.props.tradingVolume}
              avgVolume10D={this.props.avgVolume10D}
              tradingActivity={this.props.tradingActivity}
              change3M={this.props.change3M}
              changePct3M={this.props.changePct3M}
              change6M={this.props.change6M}
              changePct6M={this.props.changePct6M}
              change1Y={this.props.change1Y}
              changePct1Y={this.props.changePct1Y}
              change2Y={this.props.change2Y}
              changePct2Y={this.props.changePct2Y}
              isMarketOpen={this.props.isMarketOpen}
            />
          }
          {
            this.props.securityType === ISSUE_TYPES.MutualFund &&
            <PerformanceSummaryMF 
              top10Holdings={this.props.topHoldings_Platform}
              prospectusFees={this.props.prospectusFees_Platform}
              nav={this.props.nav}
              isMarketOpen={this.props.isMarketOpen}
              shareClassInformation={this.props.shareClassInformation_Platform.data}
              trailingNav={this.props.trailingNav}
              trailingMarketPrice={this.props.trailingMarketPrice}
              dateAsOfAnnualized={this.props.dateAsOfAnnualized}
              dividendData={this.props.dividendData}
              userType='Tracker'
              disclosureDocumentsLink_PricingAndFees={`${globalConfig.disclosureDocumentsLink_Tracker_PricingAndFees}`}
              dividendChart={this.props.dividendChart}
            />
          }
          {
            this.props.securityType === ISSUE_TYPES.Etf &&
            <PerformanceSummaryETF 
              lastPrice={this.props.lastPrice}
              todaysPriceChange={this.props.todaysPriceChange}
              todaysPricePercentChange={this.props.todaysPricePercentChange}
              open={this.props.open}
              previousClose={this.props.previousClose}
              tradingVolume={this.props.tradingVolume}
              sharesOutstanding={this.props.sharesOutstanding}
              nav={this.props.navETF}
              tradingActivity={this.props.tradingActivity}
              top10Holdings={this.props.topHoldings_Platform}
              prospectusFees={this.props.prospectusFees_Platform}
              shareClassInformation={this.props.shareClassInformation_Platform.data}
              trailingNav={this.props.trailingNav}
              trailingMarketPrice={this.props.trailingMarketPrice}
              isMarketOpen={this.props.isMarketOpen}
              dateAsOfAnnualized={this.props.dateAsOfAnnualized}
              dividendData={this.props.dividendData}
              userType='Tracker'
              disclosureDocumentsLink_PricingAndFees={`${globalConfig.disclosureDocumentsLink_Tracker_PricingAndFees}`}
              dividendChart={this.props.dividendChart}
            />
          }
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    fetching: state.snapshotV2Reducer.performanceSummary.data.fetching,
    fundIndicator_Platform: state.snapshotV2Reducer.fundIndicator_Platform,
    topHoldings_Platform: state.snapshotV2Reducer.performanceSummary.data.topHoldings_Platform,
    prospectusFees_Platform: state.snapshotV2Reducer.performanceSummary.data.prospectusFees_Platform,
    shareClassInformation_Platform: state.snapshotV2Reducer.performanceSummary.data.shareClassInformation_Platform,
    trailingNav: state.snapshotV2Reducer.performanceSummary.data.trailingMonthEnd_Platform.data.items[1].values,
    trailingMarketPrice: state.snapshotV2Reducer.performanceSummary.data.trailingMonthEnd_Platform.data.items[0].values,
    dateAsOfAnnualized: state.snapshotV2Reducer.performanceSummary.data.trailingMonthEnd_Platform.data.metaData.performance.dateAsOf,
    revenueChart: {
      fetchingRevenueChart: state.snapshotV2Reducer.revenueChart.data.fetching,
      chartUrl: state.snapshotV2Reducer.revenueChart.data.chartUrl,
      chartHeight: state.snapshotV2Reducer.revenueChart.data.chartHeight,
      chartWidth: state.snapshotV2Reducer.revenueChart.data.chartWidth
    },
    dividendChart: {
      fetchingDividendChart: state.snapshotV2Reducer.dividendChart.data.fetching,
      chartUrl: state.snapshotV2Reducer.dividendChart.data.chartUrl,
      chartHeight: state.snapshotV2Reducer.dividendChart.data.chartHeight,
      chartWidth: state.snapshotV2Reducer.dividendChart.data.chartWidth,
      chartFrequencyType: state.snapshotV2Reducer.dividendChart.data.chartFrequencyType,
      dataCoordinates: state.snapshotV2Reducer.dividendChart.data.dataCoordinates,
      isMobileView: state.snapshotV2Reducer.dividendChart.data.isMobileView
    },
    symbolXREFData: state.snapshotV2Reducer.symbolXREFData_Platform,
    dividendData: state.snapshotV2Reducer.equityDividend_Platform.data.dividends[0].dividend,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    requestSnapshotV2FundIndicatorData: (wsodIssue) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2FundIndicatorData(wsodIssue))
    },
    requestSnapshotV2PerformanceSummaryData: (wsodIssue) => {
      return dispatch(SnapshotV2Actions.requestSnapshotV2PerformanceSummaryData(wsodIssue, 'MSFT'))
    },
    requestRevenueChart: (wsodIssue, width) => {
      return dispatch(SnapshotV2Actions.requestRevenueChart(wsodIssue, width))
    },
    requestDividendChart: (wsodIssue, width, securityType) =>{
      if (SHOW_DIVIDEND_DATA) {
        return dispatch(SnapshotV2Actions.requestDividendChart(wsodIssue, width, securityType))
      }
    },
    setFetchingDataFlag: (storeObjWithFetchingFlagValue) => {
      return dispatch(SnapshotV2Actions.setFetchingDataFlag(storeObjWithFetchingFlagValue))
    },
    requestSnapshotV2DividendData:(instrumentXIDs, securityType) => {
      if (SHOW_DIVIDEND_DATA) {
        return dispatch(SnapshotV2Actions.requestSnapshotV2DividendData(instrumentXIDs, securityType))
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceSummaryContainer)
