/* eslint-disable */ 
export default response => {
  if (window.Debug) {
    const responseData = response.data
      .replace(/(Debug\.url.*;)/g, '')
    try {
      let o
      eval(`o=${responseData}`)
      response.data = o
    } catch (e) {
      e.message = `Error writing to debug: ${e.message}`
      throw e
    }

    // Fallback if response did not serialize correctly; with a little luck this code never gets hit.
    if (!response.data || !Object.keys(response.data).length) {
      // First line of string contains JSON
      const firstLine = responseData.split('\n')[0]
      let parsedData

      try {
        parsedData = JSON.parse(firstLine)
      } catch (e) {
        throw new Error('Unable to convert response to JSON object')
      }

      response.data = parsedData
    }
  }
}
