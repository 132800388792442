/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import { IsClientAccess } from '../../../common.v2/CommonMethods'
import { Format } from 'stifel-intyce-pattern-library'
import { DATE_TIME_CONSTANTS, ISSUE_TYPES } from '../../../../utils/appConstants'

class Chart extends React.Component {
  constructor (props) {
    super(props)
    this.getChart = this.getChart.bind(this)
    this.loadChartWorkScript = this.loadChartWorkScript.bind(this)
    this.state = {
      isResizeChart: true
    }
  }

  componentDidMount () {
    if (!window.ChartworksBuilder) {
      this.loadChartWorkScript()
    }
    let loadInterval = window.setInterval(() => {
      if (window.ChartworksBuilder) {
        clearInterval(loadInterval)
        this.getChart()
      }
    }, 1000)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.wsodIssue !== this.props.wsodIssue) {
      console.log('ChartCall...Update')
      this.getChart()
    }
  }

  loadChartWorkScript () {
    this.script = document.createElement('script')
    this.script.onload = this.getChart
    this.script.src = window.MD.CHARTWORKS_CDN_URL
    this.script.type = 'text/javascript'
    this.script.async = true

    document.body.appendChild(this.script)
  }

  getChart () {
    if (window.ChartworksBuilder && document.getElementById('chartTarget')) {
      const timeFrame = ['1d', '5d', '3m', '1y', '5y']

      let inputs = {
        'displaySymbol': this.props.symbol && this.props.symbol.toString(),
        'xid': this.props.wsodIssue && this.props.wsodIssue.toString(),
        'target': '#chartTarget',
        'width': 100,
        'upperPanelHeight': 50,
        'lowerPanelHeight': 50,
        'template.size': 'large',
        'feature.tools': false,
        'feature.events': false,
        'feature.events.dividends': false,
        'feature.events.splits': false,
        'feature.symbolSearch': false,
        'feature.markerType': false,
        'feature.panZoom': false,
        'feature.indicators.menuType': this.props.isMobileView ? 'list' : 'default',
        'feature.large.timeframes': timeFrame,
        'feature.small.timeframes': timeFrame,
        'panel.upper.size.height': 250,
        'panel.upper.indicator': [{'id': this.props.showHighLow ? 'highlow' : ''}],
        'style.indicator.highlow.highColor': '#555B63',
        'style.indicator.highlow.lowColor': '#555B63',
        'style.indicator.price.lineWidth': 3,
        'customEvents': [{
          'label': 'Splits',
          'display': false,
          'minHorizDistance': 10,
          'minVertDistance': 20,
          'dataset': [{
            'date': (this.props.splitHistory ? this.props.splitHistory?.ExDate : ''),
            'iconCharacter': 'S',
            'iconCharacterColor': 'white',
            'iconBackgroundColor': '#2BA92B',
            'rolloverTitle': 'Split',
            'rolloverBodyText': 'Split Ratio: ' + ((this.props.splitHistory && this.props.splitHistory.Terms) 
              ? this.props.splitHistory.Terms.replace('for', ':') : '')
          }]
        }, {
          'label': 'Dividends',
          'display': false,
          'minHorizDistance': 10,
          'minVertDistance': 20,
          'dataset': [{
            'date': (this.props.dividendHistory ? this.props.dividendHistory?.ExDate : ''),
            'iconCharacter': 'D',
            'iconCharacterColor': 'white',
            'iconBackgroundColor': '#2BA92B',
            'rolloverTitle': 'Dividend',
            'rolloverBodyHTML': 'Ex-Date: ' + (this.props.dividendHistory ? Format.dateTimeFormatter(this.props.dividendHistory?.ExDate, DATE_TIME_CONSTANTS.AS_OF_DATE_FORMAT) : '') + '<br /> Payment: $' + (this.props.dividendHistory ? (this.props.issueType === ISSUE_TYPES.Stock ? Format.formatNumber(this.props.dividendHistory?.GrossAmount) : Format.formatNumber(this.props.dividendHistory?.NetAmount)) : '')
          }]
        }],
        token: IsClientAccess() ? localStorage.getItem('c_access_token') : localStorage.getItem('access_token')
      }

      let chart = new ChartworksBuilder(inputs)
      if (this.state.isResizeChart) {
        this.state.isResizeChart = false
        this.props.handleResize()
      }
      return chart
    }
  }

  render () {
    return (
      <div id='chartTarget' />
    )
  }
}

Chart.propTypes = {
  symbol: PropTypes.string.isRequired,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  handleResize: PropTypes.func,
  isMobileView: PropTypes.bool.isRequired,
  showHighLow: PropTypes.bool,
  dividendHistory: PropTypes.object,
  splitHistory: PropTypes.object,
  issueType: PropTypes.string
}

export default Chart
