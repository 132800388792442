import Types from './types'
import { API_END_POINTS, ISSUE_TYPES, SHOW_DIVIDEND_DATA } from '../utils/appConstants'

const setFetchingDataFlag = (response) => ({
  type: Types.SET_FETCHING_DATA_FLAG,
  payload: response
})

const receiveWatchlists = (response) => {
  return {
    type: Types.GET_WATCHLISTS_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestWatchlists = () => {
  return {
    type: Types.GET_WATCHLISTS_DATA_API,
    apiCalls: [
      {
        resultKey: 'watchlists',
        endPoint: `${API_END_POINTS.WATCHLIST_PLATFORM}?_=${new Date().getTime()}`,
        params: { },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveWatchlists
  }
}

const receivemanageWatchlist = (response) => {
  return {
    type: Types.MANAGE_WATCHLISTS_API_SUCCESS,
    payload: response.data
  }
}

const manageWatchlist = (data) => {
  return {
    type: Types.MANAGE_WATCHLIST_API,
    apiCalls: [
      {
        resultKey: 'manageWatchlist',
        endPoint: `${API_END_POINTS.WATCHLIST_PLATFORM}${data.watchlistId ? `/${data.watchlistId}` : ''}`,
        params: {name: data.name, descption: data.descption},
        ajaxType: data.actionType,
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receivemanageWatchlist
  }
}

const receiveWatchlistHoldings = (response) => {
  return {
    type: Types.GET_WATCHLISTS_HOLDINGS_DATA_API_SUCCESS,
    payload: response.data
  }
}

// this needs to be refactor for to remove dependentAPICalls
const requestWatchlistHoldings = (id, doNotLoadHoldingsDetails = false, updateFetch = false) => {
  let holdingsApi = {
    resultKey: 'watchlistHoldings',
    endPoint: API_END_POINTS.WATCHLIST_PLATFORM + '/' + id + '/holdings',
    params: { watchlistId: id, updateFetch: updateFetch },
    ajaxType: 'GET',
    apiType: 'PLATFORM'
  }

  if (!doNotLoadHoldingsDetails) {
    holdingsApi.dependentAPICalls = {
      resultKey: 'watchlistHoldings',
      endPoint: API_END_POINTS.WATCHLIST_PERFORMANCE,
      params: { wsodIssues: [], watchlistId: id }, // will be set from parent call
      ajaxType: 'GET'
    }
  }
  return {
    type: Types.GET_WATCHLISTS_HOLDINGS_DATA_API,
    apiCalls: [
      holdingsApi
    ],
    onSuccess: receiveWatchlistHoldings
  }
}

const receiveAddSymbolsToWatchlist = (response) => {
  return {
    type: Types.ADD_SYMBOL_TO_WATCHLIST_DATA_API_SUCCESS,
    payload: response.data
  }
}

const requestAddSymbolToWatchlist = (id, xid) => {
  return {
    type: Types.ADD_SYMBOL_TO_WATCHLIST_DATA_API,
    apiCalls: [
      {
        resultKey: 'symbolsToWatchlist_Platform',
        endPoint: API_END_POINTS.WATCHLIST_PLATFORM + '/' + id + '/holdings',
        params: {
          holdings: [
            {
              xid: xid
            }
          ]
        },
        ajaxType: 'POST',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveAddSymbolsToWatchlist
  }
}

const receiveDeleteSymbol = (response) => {
  return {
    type: Types.DELETE_SYMBOL_FROM_WATCHLIST_API_SUCCESS,
    payload: response.data
  }
}

const requestDeleteSymbol = (watchlistId, holdingId) => {
  return {
    type: Types.DELETE_SYMBOL_FROM_WATCHLIST_API,
    apiCalls: [
      {
        resultKey: 'deleteSymbolFromWatchlistResponse',
        endPoint: API_END_POINTS.WATCHLIST_PLATFORM + '/' + watchlistId + '/holdings?holdingId=' + holdingId,
        params: {},
        ajaxType: 'DELETE',
        apiType: 'PLATFORM'
      }
    ],
    onSuccess: receiveDeleteSymbol
  }
}

const receiveWatchlistsHoldings = (response) => {
  return {
    type: Types.REQUEST_WATCHLISTS_HOLDINGS_API_SUCCESS,
    payload: response.data
  }
}

const requestWatchlistsHoldings = (watchlistsIds = []) => {
  let tempApiCalls = []
  watchlistsIds.map((id) => {
    tempApiCalls.push(
      {
        resultKey: `watchlist_${id}`,
        endPoint: `${API_END_POINTS.WATCHLIST_PLATFORM}/${id}/holdings?_=${new Date().getTime()}`,
        params: { watchlistId: id },
        ajaxType: 'GET',
        apiType: 'PLATFORM'
      }
    )
  })

  return {
    type: Types.REQUEST_WATCHLISTS_HOLDINGS_API,
    apiCalls: tempApiCalls,
    onSuccess: receiveWatchlistsHoldings
  }
}

const receiveMultipleAPICallsResponse = (response) => {
  return {
    type: Types.REQUEST_MULTIPLE_CALLS_API_SUCCESS,
    payload: response.data
  }
}

const requestMultipleAPICalls = (apiInputs = []) => {
  let tempApiCalls = []
  apiInputs.map((obj) => {
    tempApiCalls.push(
      {
        resultKey: obj.resultKey,
        endPoint: obj.endPoint,
        params: obj.params || {},
        ajaxType: obj.ajaxType || 'GET',
        apiType: 'PLATFORM'
      }
    )
  })

  return {
    type: Types.REQUEST_MULTIPLE_CALLS_API,
    apiCalls: tempApiCalls,
    onSuccess: receiveMultipleAPICallsResponse
  }
}

const requestWatchlistHoldingXidInstruments = (symbols) => {
  let tempSymbols = []
  symbols.map((sym) => {
    if (sym) {
      tempSymbols.push(
        {
          symbol: sym,
          isCaseSensitive: false
        }
      )
    }
  })
  let watchlistXidsApi = {
    resultKey: 'watchlistHoldingXidInstruments_Platform',
    endPoint: API_END_POINTS.XID_FROM_SYMBOL_PLATFORM,
    params: { symbols: tempSymbols },
    ajaxType: 'POST',
    apiType: 'PLATFORM'
  }
  return {
    type: Types.REQUEST_WATCHLIST_XID_INSTRUMENT_API,
    apiCalls: [
      watchlistXidsApi
    ],
    onSuccess: receiveWatchlistHoldingXidInstruments
  }
}

const receiveWatchlistHoldingXidInstruments = (response) => {
  return {
    type: Types.REQUEST_WATCHLIST_XID_INSTRUMENT_API_SUCCESS,
    payload: response.data
  }
}

const requestWatchlistHoldingDividends = (xids, dataSource) => {
  if (!SHOW_DIVIDEND_DATA) {
    return {
      type: Types.SET_FETCHING_DATA_FLAG,
      payload: {
        watchlistHoldings_Platform: {
          fetching: false,
          isHoldingFetched: false,
          isXidsFetched: false
        }
      }
    }
  }

  // let holdingDividendsApi = {
  //   resultKey: 'watchlistHoldingDividends',
  //   endPoint: API_END_POINTS.EQUITY_DIVIDEND + '',
  //   params: {instrumentXIDs: xids.join(','), dataSource: dataSource},
  //   ajaxType: 'GET'
  // }
  let holdingDividendsApiArray = []
  Object.keys(xids).forEach((key, index) => {
    dataSource = 'CURRENT_EQUITY_DIVIDEND'
    switch (key) {
      case ISSUE_TYPES.Stock:
        dataSource = 'CURRENT_EQUITY_DIVIDEND'
        break
      case ISSUE_TYPES.Etf:
        dataSource = 'CURRENT_ETF_DIVIDEND'
        break
      case ISSUE_TYPES.MutualFund:
        dataSource = 'CURRENT_MUTUALFUND_DIVIDEND'
        break
      default:
        dataSource = 'CURRENT_EQUITY_DIVIDEND'
        break
    }
    holdingDividendsApiArray.push({
      resultKey: 'watchlistHoldingDividends_' + key,
      endPoint: API_END_POINTS.EQUITY_DIVIDEND + '',
      params: {instrumentXIDs: xids[key].join(','), dataSource: dataSource},
      ajaxType: 'GET'
    })
  })
  return {
    type: Types.REQUEST_WATCHLIST_DIVIDEND_API,
    apiCalls: holdingDividendsApiArray,
    onSuccess: receiveWatchlistHoldingDividends
  }
}

const receiveWatchlistHoldingDividends = (response) => {
  return {
    type: Types.REQUEST_WATCHLIST_DIVIDEND_API_SUCCESS,
    payload: response.data
  }
}

export {
  setFetchingDataFlag,
  requestWatchlists,
  receiveWatchlists,
  manageWatchlist,
  receivemanageWatchlist,
  requestWatchlistHoldings,
  receiveWatchlistHoldings,
  requestAddSymbolToWatchlist,
  receiveAddSymbolsToWatchlist,
  requestDeleteSymbol,
  receiveDeleteSymbol,
  requestWatchlistsHoldings,
  receiveWatchlistsHoldings,
  requestMultipleAPICalls,
  receiveMultipleAPICallsResponse,
  requestWatchlistHoldingDividends,
  receiveWatchlistHoldingDividends,
  requestWatchlistHoldingXidInstruments,
  receiveWatchlistHoldingXidInstruments
}
